<template>
    <div class="swiper-container">
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item,index) in img" :key="index" @click="changeImg(item)">
                <img class="images" :src="item.url" alt="">
            </div>
        </div>
        <!-- 分页器 -->
        <div class="swiper-pagination"></div>
        <!-- 导航按钮 -->
        <div class="swiper-button-prev">
            <div class="button-prev swiper-prev iconfont el-icon-arrow-left"></div>
        </div>
        <div class="swiper-button-next">
            <div class="button-prev swiper-next iconfont el-icon-arrow-right"></div>
        </div>
    </div>
</template>
<script>
import Swiper from 'swiper'
export default {
    data() {
        return {
            img: this.imgs
        }
    },
    props: {
        imgs: {
            type: Array
        }
    },
    methods: {
        changeImg(item) {
            this.$emit('changeImg',item)
        }
    },
    mounted() {
        this.$nextTick(()=>{
            new Swiper('.swiper-container',{
                speed: 300,
                autoplay: {
                    delay: 3000
                },
                loop: true,
                grabCursor: true,
                autoHeight: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            })
        })
    }
}
</script>
<style lang="less" scoped>
.swiper-container {
    width: 100%;
    // height: 200px;
    // --swiper-theme-color: #ff6600;
    // --swiper-pagination-color: #00ff33;
}
.images {
    width: 100%;
    // height: 200px;
}
.button-prev {
    width: 44px;
    height: 44px;
    text-align: center;
    line-height: 44px;
    background: #007aff;
    color: #FFF;
    border-radius: 50%;
}
</style>
<style>
.swiper-pagination-bullet {
    width: 30px;
    height: 5px;
    border-radius: 10px;
}
.swiper-button-prev:after, .swiper-button-next:after {
    content: ''
}
.swiper-button-prev,.swiper-button-next {
    width: calc(var(--swiper-navigation-size) / 44 * 44);
}
</style>