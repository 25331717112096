<template>
  <div id="home">
    <div class="home-header">
      <div class="header-title-left">
        <img class="left-logo" src="../img/logo-white.png" alt="">
      </div>
      <div class="header-title-right">
        <i class="title-right-text">迪倍特</i>
        <img class="title-right-icon" src="../img/right-opeartion.png" alt="" @click="toggle">
      </div>
    </div>
    <div class="menu-mask" v-show="closeMenuFlag" @click="closeMenu"></div>
    <div class="menu-wrapper" :class="closeMenuFlag?'slide-menu-wrapper':''">
      <div class="menu-close">
        <span @click="closeMenu">×</span>
      </div>
      <div class="menu-list">
        <div class="menu-list-item" v-for="(item,index) in menuList" :key="index" @click="menuTap(item)">
          <span :class="currentMenu==item.router?'menu-item-active':''">{{item.name}}</span>
        </div>
      </div>
      <div class="menu-logo">
        <img src="../img/logo-blue.png" alt="">
      </div>
    </div>
    <div>
      <router-view></router-view>
    </div>
    <div class="scroll-top" :class="isScroll?'scroll-top-show':''" @click="scrollTop">
      <i class="scroll-icon el-icon-arrow-up"></i>
    </div>
    <!-- <div>
      <Swiper :imgs="imgs" @changeImg="changeImg"></Swiper>
    </div>
    <el-carousel :interval="4000" type="card" ref="slideCarousel">
      <el-carousel-item v-for="item in 4" :key="item">
        <h3 class="small">{{ item }}</h3>
      </el-carousel-item>
    </el-carousel> -->
  </div>
</template>

<script>
import Swiper from '../components/Swiper';
export default {
  components: {
    Swiper
  },
  data() {
    return {
      name: '',
      roleId: 0,
      imgs: [
        // { url: require('@/img/bg_01.jpg') },
        // { url: require('@/img/bg_02.jpg') },
        // { url: require('@/img/bg_03.jpg') },
      ],
      closeMenuFlag: false,
      currentMenu: 'index',
      menuList: [
        { router: 'index', name: '首页' },
        { router: 'operation', name: '运维系统' },
        { router: 'digitalTwins', name: '数字孪生' },
        { router: 'configuration', name: '组态模块' },
        { router: 'services', name: '技术与服务' },
        { router: 'about', name: '关于迪倍特' },
        { router: 'contactUs', name: '联系我们' },
      ],
      isScroll: false,
    }
  },
  watch:{
    '$route.path':function(newVal,oldVal){
      this.currentMenu = newVal.substring(1)
    }
  },
  methods: {
    // 顶部菜单展开切换
    toggle() {
      this.closeMenuFlag = true
    },
    // 菜单item项
    menuTap(item) {
      this.currentMenu = item.router
      sessionStorage.setItem('currentMenu', item.router)
      this.$router.push({ name: item.router })
      this.closeMenuFlag = false
    },
    // 关闭菜单
    closeMenu() {
      this.closeMenuFlag = false
    },
    // 监听滚动条
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if(scrollTop > 1700) {
        this.isScroll = true
      }else {
        this.isScroll = false
      }
    },
    // 回到顶部
    scrollTop() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if(scrollTop > 0) {
        const timeTop = setInterval(() => {
          document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 50;
          if(scrollTop <= 0) {
            clearInterval(timeTop)
          }
        },10)
      }
    }
    // slideBanner() {
    //   var box = document.querySelector('.el-carousel__container');
    //   var startPoint = 0;
    //   var stopPoint = 0;
    //   var restPoint = function () {
    //     startPoint = 0
    //     stopPoint = 0
    //   }
    //   box.addEventListener('touchstart',function(e) {
    //     startPoint = e.changeTouches[0].pageX;
    //   })
    //   box.addEventListener('touchmove',function(e) {
    //     stopPoint = e.changeTouches[0].pageX;
    //   })
    //   let that = this
    //   box.addEventListener('touchend',function(e) {
    //     if(stopPoint==0||startPoint-stopPoint==0) {
    //       resetPoint();
    //       return;
    //     }
    //     if(startPoint-stopPoint>0) {
    //       restPoint();
    //       that.$refs.slideCarousel.next();
    //       return;
    //     }
    //     if(startPoint-stopPoint<0) {
    //       resetPoint();
    //       that.$refs.slideCarousel.prev();
    //       return;
    //     }
    //   })
    // }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
    if(sessionStorage.getItem('currentMenu')) {
      this.currentMenu = sessionStorage.getItem('currentMenu')
    }
  }
}
</script>

<style lang="less" scoped>
#home {
  padding-top: 90px;
}
// web端样式
.home-header {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000000;
  color: #FFFFFF;
  padding: 0 30px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  user-select: none;
}
.header-title-left {
  .left-logo {
    height: 120px;
  }
}
.header-title-right {
  display: flex;
  align-items: center;
  .title-right-text {
    font-weight: bold;
    font-size: 26px;
    padding-right: 30px;
  }
  .title-right-icon {
    height: 35px;
    cursor: pointer;
  }
}
.menu-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  transition: all .3s ease;
}
.menu-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  width: 480px;
  height: 100%;
  background: #FFFFFF;
  user-select: none;
  transform: translateX(500px);
  transition: all .3s ease;
  .menu-close {
    height: 50px;
    text-align: right;
    line-height: 50px;
    padding: 30px;
    span {
      display: inline-block;
      width: 35px;
      height: 35px;
      border: 3px solid #000000;
      border-radius: 50%;
      text-align: center;
      line-height: 35px;
      font-size: 35px;
      cursor: pointer;
      transition: all .3s ease;
    }
  }
  .menu-close span:hover {
      border: 3px solid #0245C5;
      color: #0245C5;
  }
  .menu-list {
    width: 100%;
    margin-top: 20px;
    padding: 0 20px;
    box-sizing: border-box;
    .menu-list-item {
      text-align: center;
      height: 80px;
      line-height: 80px;
      cursor: pointer;
      transition: all .3s ease;
      span {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .menu-list-item:hover {
      background: #e0ebff;
      border-radius: 15px;
    }
    .menu-item-active {
      color: #0245C5;
    }
  }
  .menu-logo {
    text-align: center;
    img {
      width: 40%;
    }
  }
}
.slide-menu-wrapper {
  transform: translateX(0px);
}
.scroll-top {
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 998;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: #FFFFFF;
  color: #00175B;
  text-align: center;
  cursor: pointer;
  display: none;
  .scroll-icon {
    display: inline-block;
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 22px;
    font-weight: bold;
  }
}
.scroll-top-show {
  display: block;
}

// 移动端样式
@media screen and (max-width:650px) {
  #home {
    padding-top: 44px;
  }
  .home-header {
    height: 44px;
    padding: 0 20px !important;
  }
  .left-logo {
    height: 80px !important;
  }
  .title-right-text {
    font-size: 18px !important;
    padding-right: 20px !important;
  }
  .title-right-icon {
    height: 20px !important;
  }
  .menu-wrapper {
    width: 40%;
    .menu-close {
      height: 44px !important;
      padding: 0 20px;
      span {
        font-size: 20px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border: 1px solid #000000;
      }
    }
    .menu-list {
      margin: 50px 0;
      .menu-list-item {
        height: 60px !important;
        line-height: 60px !important;
        span {
          font-size: 14px !important;
        }
      }
    }
    .menu-logo {
      margin-top: 30px;
      img {
        width: 60%;
      }
    }
  }
}
</style>
<style>
 /* .el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
} */
</style>